export const catTree  = (treeArray:any, parentId = 0) =>{
    const output:any = [];
    treeArray.map((node:any,k:any)=>{
        if(parseInt(node.catid) == parentId && node.show_header == "1"){
            const children = catTree(treeArray,node.id)
            if(children ){
                node.children = children
                node.child = children.length
            }else{
                node.child = 0
            }
            output.push(node)
        }
    })
    return output
}
export const strUrl = (v:any)=>{
    if(v){
        return encodeURIComponent(v.replaceAll(" ","_").replaceAll("'","").replaceAll('"',""))
    }else{
        return ""
    }
}
export const getUrl = (v:any)=>{
    let url = ""
    if(v.id == 0){
        url ="#"
    }else{
        if(v.url){
            url =v.url
        }else{
            if(v.type == 1){
                url =   "/cat/"+v.id+"/"+strUrl(v.title)

            }else if(v.type == 2){
                url =   "/page/"+v.id+"/"+strUrl(v.title)
            }

        }
    }

    return url
}

export const getarticleUrl = (v:any)=>{
    let url = ""
    url =   "/n/"+v.id+"/"+strUrl(v.title)
    return url
}

export const CategoryType =[
    {
        path:"/cat/"

    },
    {
        path:"/news/"
    },
    {
        path:"/news/"
    }
]
