import {Col, Row} from "react-bootstrap";
import ArticleOverlay from "../articleBox/articleOverlay";
import ArticleSmall from "../articleBox/articlesmall";

function Category({data=[] as any,catName="آخر الأخبار",catId=0}) {
    return(
        <Row>
            <Col md={12}>
                <h2 className={"cat_header fw-bold h4  ps-2"}><span>{catName}</span></h2>
            </Col>
            <Col md={12} className={"mb-3"}>
                <ArticleOverlay ratio={{w:700,h:700/1.77777}} data={data[0]} />
            </Col>

            {
                data.map((val:any,k:any)=>{
                    if(k<1){return }
                    return(
                        <Col md={6} key={k}>
                            <ArticleSmall ratio={{w:120,h:90}} data={val} />
                        </Col>
                    )
                })
            }
        </Row>
    )
}
export default Category
