import {Link} from "react-router-dom";
import Picture from "../picture";
import {getarticleUrl} from "../../helper/function";
import "./article.css"
function ArticleSummary2({data={color:"",id:0,title:"",catName:"",image:"",summary:""},ratio={w:0,h:0},mobRatio={w:0,h:0}}) {
    return(
        <Link className={"link"} to={getarticleUrl(data)} title={data.title}>
            <article className={"text-white"}>
                <div className={"position-relative imgCut"}><Picture image={data.image} title={data.title} mobRatio={mobRatio} ratio={ratio} /></div>

                <div className={"light px-2 py-1"}>
                    {
                        data.catName &&
                        <span style={{borderRight:"4px solid "+data.color}} className={"ps-1"} >{data.catName}</span>
                    }
                    <h3 className={"mt-1"} >{data.title}</h3>
                </div>
            </article>
        </Link>
    )
}
export default ArticleSummary2
