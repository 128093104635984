import {
    FaChevronUp,
     FaFacebookSquare,
    FaInstagramSquare,
     FaTwitterSquare,
     FaWhatsappSquare,
    FaYoutubeSquare
} from "react-icons/fa";
import {Col, Container, Image, Row} from "react-bootstrap";
import logo from "../../assets/images/logo.svg"
import {data, setting} from "../../helper/setting";
import {Link} from "react-router-dom";
import {FaSquareTwitter} from "react-icons/fa6";
import {getUrl} from "../../helper/function";

function Footer() {
    return(
        <footer id={"footer"} className={"bg-white"}>
            <div style={{backgroundColor:"#e9eff0"}} className={"py-3 text-center"}>
                <a href={"#root"} className={"h5"}>العودة للأعلى <FaChevronUp /></a>
            </div>
            <div className={""}>
                <Container>
                    <Row className={"pt-4 "} >
                        <Col md={4}  >
                            <Row className={"h-100 align-items-center"}>
                                <Col md={12}>
                                    <div className={"text-center text-md-start"}>
                                        <Link  to={"/"} title={setting.title}>
                                            <Image srcSet={logo} alt={setting.title} style={{maxWidth:100}} />
                                        </Link>
                                    </div>
                                    <div className={"mt-4"} style={{color:"#777"}}>
                                        {data.Setting.footerdescription}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={"offset-md-1 d-none d-lg-block"} md={2}>
                            <ul className={"list-unstyled "}>
                                {
                                    data.footerCateg.filter((vv:any)=>{return vv.type ==1}).map((v:any,k:number)=>{
                                        if(k > 5){ return }
                                        return(
                                            <li key={k} className={"mb-2"}>
                                                <Link to={getUrl(v)}>{v.title}</Link>
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                        </Col>
                        <Col md={2} className={"d-none d-lg-block"}>
                            <ul className={"list-unstyled "}>
                                {
                                    data.footerCateg.filter((vv:any)=>{return vv.type ==1}).map((v:any,k:number)=>{
                                        if(k < 5){ return }
                                        return(
                                            <li key={k} className={"mb-2"}>
                                                <Link to={getUrl(v)}>{v.title}</Link>
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                        </Col>
                        <Col md={3} className={""}>
                            <ul className={"list-unstyled d-none d-lg-block"}>
                                {
                                    data.footerCateg.filter((vv:any)=>{return vv.type !=1}).map((v:any,k:number)=>{
                                        return(
                                            <li key={k} className={"mb-2"}>
                                                <Link to={getUrl(v)}>{v.title}</Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>

                            <div className={"mt-4 text-center text-lg-start"}>
                                {
                                    data.Setting.whatsapp &&
                                    <a target={"_blank"} aria-label={"whatsapp"} className={"d-inline-block mx-1"} href={data.Setting.whatsapp}><FaWhatsappSquare color={"#25D366"} size={33} /></a>
                                }
                                {
                                    data.Setting.facebook &&
                                    <a target={"_blank"} aria-label={"facebook"} className={"d-inline-block mx-1"} href={data.Setting.facebook}><FaFacebookSquare color={"#3b5998"} size={33} /></a>
                                }
                                {
                                    data.Setting.twitter &&
                                    <a target={"_blank"} aria-label={"twitter"} className={"d-inline-block mx-1"} href={data.Setting.twitter}><FaTwitterSquare color={"#00acee"} size={33} /></a>
                                }
                                {
                                    data.Setting.instagram &&
                                    <a target={"_blank"} aria-label={"instagram"} className={"d-inline-block mx-1"} href={data.Setting.instagram}><FaInstagramSquare color={"#C13584"} size={33} /></a>
                                }
                                {
                                    data.Setting.youtube &&
                                    <a target={"_blank"} aria-label={"youtube"} className={"d-inline-block mx-1"} href={data.Setting.youtube}><FaYoutubeSquare color={"#c4302b"} size={33} /></a>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12} className={"text-center my-2 mb-3"}>
                            <hr style={{borderColor:"#e9eff0",opacity:1}} className={"py-0 my-0 mb-2"}  />
                            <div >
                                جميع الحقوق محفوظة ©️ {new Date().getFullYear()} {setting.title}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}
export default Footer
