import logo from "../assets/images/logo.svg"
import {Image} from "react-bootstrap";
function Loading() {
    return(
        <div className={"h-100 justify-content-center align-items-center d-flex"}>
            <Image width={300} className={"zoom"} srcSet={logo} alt={"انتظر من فضلك"}/>
        </div>
    )
}
export default Loading
