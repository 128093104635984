import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {data, setting} from "../helper/setting";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import { Helmet,HelmetProvider } from 'react-helmet-async';
import MergeArticle from "../container/catBox/mergeArticle";
import Picture from "../container/picture";

function Details() {
    const {id} = useParams()
    const [data2,setdata] = useState([] as any)
    const [loading,setloading] = useState(true)
    const [script,setscript] = useState([] as any)
    useEffect(()=>{
        getData()
    },[id])

    const getData = () => {
        setloading(true)
        axios.get(setting.apiUrl+"ArticleApi/Get/"+id)
            .then((res)=>{
                setdata(res.data.Data)
                const string:string = res.data.Data.details

                if(string.match(/<script [^>]*src="[^"]*"[^>]*>/gm)){
                    setscript(string.match(/<script [^>]*src="[^"]*"[^>]*>/gm)!.map(x => x.replace(/.*src="([^"]*)".*/, '$1')));
                }

                setloading(false)
            }).catch((error)=> {

        })
    }

    return(
        <Container className={"py-4"}>
            <Row className={"justify-content-center gx-md-4 "}>
                <Col md={8} className={"pt-3 "}>
                {
                    loading ?
                        <div className={"text-center my-5 py-5"} ><Spinner aria-label={"انتظر من فضلك"} variant={"success"} /></div>
                        :
                        <>
                            {data2 &&
                                <>

                                        <h1 className={"fw-bold h2 mb-3"}>{data2.title}</h1>
                                        <Picture image={data2.image} title={data2.title} ratio={{w:700,h:700/1.77777}} mobRatio={{w:350,h:350/1.77777}} />
                                        <div className={"d-flex pt-3 align-items-center justify-content-between"}>
                                            <div>الناشر : {data2.writer}</div>
                                            <div dir={"ltr"}>{data2.create_date}</div>
                                        </div>

                                        <div className={"details text-justify"} dangerouslySetInnerHTML={{__html:data2.details.replaceAll("h2","h3")}}></div>


                                        <Helmet  encodeSpecialCharacters={true} async title={data.Setting.title+" - "+data2.title}    defaultTitle={data.Setting.title}>
                                            <link rel="canonical" href={window.location.origin + window.location.pathname} />
                                            <meta name="description" content={data2.summary} />
                                            <meta property="og:type" content="article" />
                                            <meta property="og:title" content="عقوبات بريطانيا على شركات مرتبطة بالجيش السوداني والدعم والسريع" />
                                            <meta property="og:url" content={window.location.origin + window.location.pathname} />
                                            <meta property="og:image" content={setting.domain + setting.public + data2.image} />
                                            <meta property="og:image:url" content={setting.domain + setting.public + data2.image} />
                                            <meta property="og:image:secure_url" content={setting.domain + setting.public + data2.image} />
                                            <meta property="og:description" content={data2.summary} />
                                            <meta property="og:site_name" content={data.Setting.title} />
                                            <meta name="twitter:card" content="summary_large_image" />
                                            <meta name="twitter:title" content={data2.title} />
                                            <meta name="twitter:description" content={data2.summary} />
                                            <meta name="twitter:image" content={setting.domain + setting.public + data2.image} />

                                            {
                                                script &&
                                                <>
                                                {script.map((v:any)=>{
                                                return(
                                                    <script key={v} defer src={v}/>
                                                )
                                                })
                                                }
                                                </>

                                            }
                                        </Helmet>





                                </>
                            }
                        </>
                }
            </Col>
                <Col md={3} className={"position-relative  "}>
                    <div className={"position-sticky start-0 top-0"}>
                        <MergeArticle catName={"زوارنا يتصفحون الآن"} data={data.mainNews.filter((v:any,k:any)=>{return k <=4})}/>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Details
