import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {data, setting} from "../helper/setting";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import Category from "../container/catBox/category";
import MergeArticle from "../container/catBox/mergeArticle";
import {Helmet} from "react-helmet-async";

function Search() {
    const {title} = useParams()
    const [data2,setdata] = useState([] as any)
    const [page,setpage] = useState(0)
    const [loading,setloading] = useState(true)
    useEffect(()=>{
        getData()
    },[title])

    const getData = () => {
        setloading(true)
        axios.post(setting.apiUrl+"CategoryApi/Search"+"?page="+page,{
            title:title
        })
            .then((res)=>{
                setdata(res.data.Data)
                setloading(false)
            }).catch((error)=> {
        })
    }


    document.addEventListener("scroll",(e)=>{

        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
            return;
        }

        setpage(page+1)

        getData()
    })

    return(
        <>

        <Container className={"py-4"}>
            <Row className={"justify-content-center gx-md-4 "}>
                <Col md={8} className={"pt-3 "}>

                {
                    loading ?
                        <div className={"text-center my-5 py-5"} ><Spinner aria-label={"انتظر من فضلك"} variant={"success"} /></div>
                        :
                       <>
                           {data2 &&
                               <>
                                   <Helmet async title={data.Setting.title+" - "+title}   defaultTitle={data.Setting.title}>
                                       <link rel="canonical" href={window.location.origin + window.location.pathname} />
                                   </Helmet>

                                       <Category data={data2} catName={title} />


                               </>
                           }
                       </>
                }
                </Col>
                <Col md={3} className={"position-relative pt-3 "}>
                    <div className={"position-sticky start-0 top-0"}>
                        <MergeArticle catName={"زوارنا يتصفحون الآن"} data={data.mainNews.filter((v:any,k:any)=>{return k <=4})}/>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    )
}
export default Search
