import {Link} from "react-router-dom";
import Picture from "../picture";
import {Col, Row} from "react-bootstrap";
import {data} from "../../helper/setting";
import ArticleSmall from "../articleBox/articlesmall";
import {getUrl} from "../../helper/function";

function ListArticle({data=[] as any,catName="زوارنا يتصفحون الآن",catId=0}) {
    const ratio={w:120,h:106 }
    const mobRatio={w:150,h:150 }
    return(
        <>

            <h2 className={"cat_header"}>
                <Link to={getUrl({type:1,title:catName,id:catId})}>{catName}</Link>
            </h2>
            <div className={"shadow-sm bg-white"}>
                <Row>
                    <Col md={12} className={""}>

                        {
                            data.map((val:any,k:number)=>{
                                return(
                                    <div key={k}>
                                        <ArticleSmall data={val} className={((k == data.length - 1) ? " border-0" : "")} />
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
            </div>


        </>
    )
}
export default ListArticle
