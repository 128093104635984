import {Col, Row} from "react-bootstrap";
import ArticleSummary2 from "../articleBox/articleSummary2";
import {getUrl} from "../../helper/function";
import {Link} from "react-router-dom";

function GridArticle2({data=[] as any,catName="أخبار الاقتصاد",catId=0}) {
    const ratio={w:350,h:350 / 1.6}
    const mobRatio={w:350,h:350 / 1.6}
    return(
        <>
            <h2 className={"fw-bold text-white mb-3"}>
                <Link to={getUrl({type:1,title:catName,id:catId})}>{catName}</Link>
            </h2>
            <Row>
                {
                   data.map((val:any,key:number)=>{
                       if( key > 3 ){return}
                        return(
                            <Col key={key}  md={3}>
                                <ArticleSummary2 data={val} ratio={ratio} mobRatio={mobRatio} />
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    )
}
export default GridArticle2
