import Picture from "../picture";
import {Link} from "react-router-dom";
import {getarticleUrl} from "../../helper/function";

function ArticleSmall({data={color:"",id:0,title:"",catName:"",image:""},ratio={w:120,h:106},mobRatio={w:120,h:106},className="shadow-sm bg-white border-0 m-0 p-0 mb-3"}) {
    return(
        <>
            <Link to={getarticleUrl(data)}  title={data.title}>
            <article className={"d-flex align-items-center  listNews  " +  className}>
                <div style={{width:"36%"}}><Picture ratio={ratio} mobRatio={ratio} image={data.image} title={data.title} /></div>
                <div style={{width:"65%",paddingRight:10}}>
                    {
                        data.catName &&
                        <span style={{borderRight:"4px solid "+data.color}} className={"ps-1"} >{data.catName}</span>
                    }
                    <h3 className={"mt-1 pe-1"}>{data.title}</h3>
                </div>
            </article>
        </Link>
        </>
    )
}
export default ArticleSmall
