const domain  = "https://oumoma.net/"
export const setting = {
    domain: domain,
    apiUrl: domain + "api/",
    title : "أمومة",
    public:"public_img/",
    img_path:domain+"img/"
}
export const data:any = {
    Setting: {},
    Category:[],
    mainNews:[],
    catNews:[],
    vodNews:[],
    footerCateg:[]
}

export const imgSize = (sizes:any,src:string)=>{
    let size = ""

    sizes.forEach((val:any)=>{

        size += setting.img_path + val.w+"/"+val.h+"/"+ setting.public +src +" " +val.w+"w, "
    })
    return size
}
