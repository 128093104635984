import React, {useEffect, useState} from "react";
import {Col, Container, Image, Navbar, Row, Offcanvas, Nav, NavDropdown, Form, Dropdown} from "react-bootstrap";
import logo from "../../assets/images/logo.svg"
import {data, setting} from "../../helper/setting";
import {catTree, getUrl} from "../../helper/function";
import {Link} from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "./header.css"
import {FiSearch} from "react-icons/fi";
import SearchInput from "../searchInput";
import axios from "axios";
import weather_img from "../../assets/images/weather_sprite.svg"
import {FaChevronDown} from "react-icons/fa";
function Header() {
    const [menuOpen, setMenuOpen] = useState(false)
    const [weather,setweather] = useState([] as any)

    useEffect(()=>{
        axios.get("https://api.skynewsarabia.com/weather/5days-forecast?countryCode=SA",{method:"get"})
            .then((res)=>{
                setweather(res.data)
            }).catch((error)=> {

            })
    },[])
    const toggleMenu = () => {
        if(window.innerWidth > 1200){
            setMenuOpen(false)
        }else{
            setMenuOpen(!menuOpen)
        }
    }

    const handleClose = () => setMenuOpen(false)


    return(
        <>
            <header>
                <div className={"topbar d-none d-md-block"}>
                    <Container className={"h-100"}>
                        <Row className={"h-100"}>
                            <Col md={6} className={"h-100 text-start"}>
                                {weather.length > 0 &&
                                    <Dropdown align={"end"}  className={"py-0 my-0 h-100"}>
                                        <Dropdown.Toggle as={"div"} variant="none" className={"text-white d-inline-flex align-items-center h-100"} id="dropdown-basic">
                                            <div className={"d-flex align-items-center h-100"}>
                                                <div>
                                                    <span className={"me-2"} style={{width:50}}>{weather[0].currentTemperature}°C</span>
                                                    <span className={"me-2"} style={{width:40}}>
                                                        <svg fill={"#fff"} width={17} height={17} className="weather_wid_expanded_view_list_item_icon"> <use
                                                            data-ng-href={weather_img+"#"+weather[0].symbolImage}
                                                            xlinkHref={weather_img+"#"+weather[0].symbolImage}></use>
                                                        </svg>
                                                    </span>
                                                    <span >{weather[0].locationName}</span>
                                                </div>
                                                <div className={"arrow px-2 ms-2 d-flex align-items-center"}>
                                                    <FaChevronDown />
                                                </div>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className={"d-flex py-3 justify-content-between"} style={{backgroundColor:"#EDEDE"}} >
                                                <span className={"h4 fw-bold"} >{weather[0].currentTemperature}°C</span>
                                                <span >
                                                        <svg width={30} height={30} className="weather_wid_expanded_view_list_item_icon"> <use
                                                            data-ng-href={weather_img+"#"+weather[0].symbolImage}
                                                            xlinkHref={weather_img+"#"+weather[0].symbolImage}></use>
                                                        </svg>
                                                    </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item style={{backgroundColor:"#D5d5d5"}} className={"py-2"}>
                                                <div className={"text-center h5"}>
                                                    {weather[0].locationName}
                                                </div>
                                            </Dropdown.Item>
                                            {
                                                weather.map((val:any,k:number)=>{
                                                    return(
                                                        <Dropdown.Item key={k} className={"d-flex px-2"}>
                                                            <span style={{width:"100%"}}>{val.dayName}</span>
                                                            <span className={"text-end me-2"}  style={{width:100,fontSize:14}}>{val.currentTemperature}°C</span>
                                                            <span style={{width:40}}>
                                                        <svg width={17} height={17} className="weather_wid_expanded_view_list_item_icon"> <use
                                                            data-ng-href={weather_img+"#"+val.symbolImage}
                                                            xlinkHref={weather_img+"#"+val.symbolImage}></use>
                                                        </svg>
                                                    </span>
                                                        </Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            </Col>
                            <Col md={6} className={"text-end"}>
                                <div className={"text-white d-inline-flex h-100 align-items-center "}>
                                    <span>{new Date().toLocaleString("ar", { month: "long",day:"numeric",year:"numeric" ,weekday:"long" })}</span>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
                <div className={"header"}>
                    <Container>
                        <Row className={" "}>
                            <Col md={12} className={" d-none d-lg-flex  justify-content-between pt-2 pb-0 pb-2 align-items-center"}>
                                <Link to={"/"} title={setting.title}>
                                    <Image srcSet={logo} alt={setting.title} width={130}  />
                                </Link>

                                <div className="header-search search-form shadow-sm  bg-white" style={{width:270,height:40}}>
                                    <SearchInput id={1} />
                                </div>

                            </Col>
                            <Col md={12} className={"py-1 py-lg-0"}>
                                <Navbar expand={"lg"} bg="none"  className={"justify-content-lg-between justify-content-start"} >
                                    <Navbar.Brand  className={"order-1 ms-2 middle-x d-lg-none order-xl-0"} as={Link} to={"./"}><LazyLoadImage loading={"lazy"} srcSet={logo}  className={" "}  style={{height:"60px"}} alt={data.Setting.title} /> </Navbar.Brand>
                                    <Navbar.Toggle  onClick={toggleMenu} className={"order-0 justify-content-center order-xl-0"} aria-controls={`offcanvasNavbar-expand-false`} />
                                    <Navbar.Offcanvas            restoreFocus={false}
                                                                 show={menuOpen}
                                                                 onHide={handleClose}

                                                                 id={`offcanvasNavbar-expand-false`} aria-labelledby={`offcanvasNavbarLabel-expand-false`} placement="start">
                                        <Offcanvas.Header closeButton >
                                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                                                <Link to={"./"}><Image loading={"lazy"} onClick={toggleMenu} className={"w-auto"}  srcSet={logo} height={40} alt={data.Setting.title} /></Link>
                                            </Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body  className={"px-4 px-md-0"}>
                                            <div className="header-search search-form mb-3 d-md-none shadow-sm text-center bg-white" style={{width:"100%",height:40}}>
                                                <SearchInput id={2} />
                                            </div>

                                            <Nav className=" text-center flex-grow-1 pe-md-3  mb-1 pe-0">
                                                <Nav.Link className={"ps-0"}  as={Link} onClick={toggleMenu} to={"./"}>الرئيسية</Nav.Link>

                                                <>
                                                    {
                                                        catTree(data.Category,0).map((v:any,k:any)=>{
                                                            if(v.show_header != 1) {return }
                                                            return(
                                                                <span className={"navlist"} key={k}>
                                                            {
                                                                v.children.length  > 0 ?
                                                                    <NavDropdown key={k} title={v.title} className={"fade-up"} id={`offcanvasNavbarDropdown-expand-`+v.id}>
                                                                        {
                                                                            v.children.map((v2:any,k2:any)=>{
                                                                                if(v2.show_header != 1) {return }
                                                                                return(
                                                                                    <NavDropdown.Item key={k2} onClick={toggleMenu} className={"text-center"} as={Link} to={getUrl(v2)}>{v2.title}</NavDropdown.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </NavDropdown>
                                                                    :
                                                                    <Nav.Link key={k} as={Link} onClick={toggleMenu} to={getUrl(v)}>{v.title}</Nav.Link>
                                                            }
                                                        </span>
                                                            )
                                                        })
                                                    }
                                                </>




                                            </Nav>
                                        </Offcanvas.Body>
                                    </Navbar.Offcanvas>
                                </Navbar>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </header>
        </>
    )
}
export default Header
