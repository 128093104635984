import React from "react";
import {Button, Form} from "react-bootstrap";
import {FiSearch} from "react-icons/fi";
import {setting} from "../helper/setting";
import {useNavigate} from "react-router-dom";

function SearchInput({id=1}) {
    const nav = useNavigate()
    const search = () => {
        const search_query = document.getElementById("search_query"+id)! as HTMLInputElement
        if(search_query){
            if(search_query.value.trim().length >=3){
                nav("/tag/"+search_query.value.trim())
                search_query.value = ""
            }
        }
    }
    return(
        <>
            <Form   id="searchFromNav" target="_self" className="position-relative h-100">
                <Form.Control required className={"border-0 rounded-0 w-100 h-100 my-0 ms-2 py-0 ps-4"} type="search" name="q"  autoComplete="off" id={"search_query" +id} placeholder={"البحث في محتوى "+setting.title} aria-label={"البحث في محتوى "+setting.title} />
                <Button variant={"none"} onClick={()=>{search()}} className="px-0 position-absolute translate-middle-y top-50 start-0 ms-2" style={{zIndex:1}} >
                    <FiSearch />
                </Button>
            </Form>
        </>
    )
}
export default SearchInput
