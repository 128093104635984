import {Col, Container, Image, Row} from "react-bootstrap";
import {data, setting} from "../helper/setting";
import {Link} from "react-router-dom";
import ArticleOverlay from "../container/articleBox/articleOverlay";
import ArticleSummary from "../container/articleBox/articleSummary";
import ListArticle from "../container/catBox/listArticle";
import GridArticle from "../container/catBox/gridArticle";
import MergeArticle from "../container/catBox/mergeArticle";
import GridArticle2 from "../container/catBox/gridArticle2";
import {Helmet} from "react-helmet-async";
import React from "react";

function Home() {

    return(
        <>
            <Helmet async title={data.Setting.title}   defaultTitle={data.Setting.title}>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
            </Helmet>
            <h1 className="page-header-hidden">{setting.title}</h1>

            <Container className={"my-3"}>
                <Row className={"gx-3"}>
                    <h2 className="page-header-hidden">القصة الرئيسية</h2>
                    {
                        data.mainNews.filter((v:any,k:number)=>{return k<=1}).map((val:any,key:number)=>{
                            let mobRatio:any = []
                            let ratio:any = []
                            if(key ==1 ){
                                ratio={w:400,h:400 * 1.165}
                                mobRatio={w:480,h:480/1.6}
                            }else{
                                ratio={w:600,h:600 / 1.76}
                                mobRatio={w:480,h:480/1.77777}
                            }
                            return(
                                <Col key={key} className={"mb-3"} md={key == 0 ? 6 : 3}>
                                    <ArticleOverlay data={val}  ratio={ratio} mobRatio={mobRatio}  />
                                </Col>
                            )
                        })
                    }

                    <Col md={3}>
                        <Row>
                            {
                                data.mainNews.filter((v:any,k:number)=>{return k>=2 && k<=3}).map((val:any,key:number)=>{
                                    let mobRatio:any = []
                                    let ratio:any = []
                                    mobRatio={w:350,h:350 / 1.777777}
                                    ratio={w:350,h:350 / 1.777777}
                                    return(
                                        <Col key={key} className={"mb-3"} md={12}>
                                            <ArticleOverlay data={val}  ratio={ratio} mobRatio={mobRatio}  />
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                    </Col>


                    {
                        data.mainNews.filter((v:any,k:number)=>{return k>=4 && k<8}).map((val:any,key:number)=>{
                            let mobRatio:any = []
                            let ratio:any = []
                            ratio={w:350,h:350 / 1.777777}
                            mobRatio={w:350,h:350 / 1.777777}
                            return(
                                <Col key={key} className={"mb-2"} md={3}>
                                    <ArticleOverlay data={val} ratio={ratio} mobRatio={mobRatio} />
                                </Col>
                            )
                        })
                    }

                    <Col md={12}>
                        <div className={"ads"}></div>
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                        <GridArticle data={ data.mainNews.filter((v:any,k:number)=>{return k>=8 && k<14})} />
                    </Col>
                    <Col md={3}>
                        <ListArticle data={data.mainNews.filter((v:any,k:number)=>{return k>=8 && k<13})} />
                    </Col>

                    <Col md={12}>
                        <div className={"ads"}></div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    {
                        data.catNews.filter((v:any,k:number)=>{return k<=3}).map((val:any,k:number)=>{
                            return(
                                <Col key={k} className={"mb-md-4 mb-3"} md={(k === 4 ) ? 9 : 3}>
                                    {
                                        (k === 4) ?
                                            <GridArticle data={val.articles} catName={val.catName} catId={val.id} />
                                            :
                                            <MergeArticle data={val.articles.filter((v:any,kk:number)=>{return kk <= 3})} catName={val.catName} catId={val.id} />
                                    }

                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>

            <div className={"py-5 mb-5 colorBg"}   >
                <Container  >
                    <Row>
                        {
                            data.catNews.filter((v:any,ks:number)=>{return (parseInt(v.id)===7)}).map((val:any,k:number)=>{
                                return(
                                    <Col key={k} md={12}>
                                        <GridArticle2 data={val.articles}  catId={val.id} />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>



            <Container>
                <Row>
                    {
                        data.catNews.filter((v:any,k:number)=>{return (k>=4 && parseInt(v.id)!==7)}).map((val:any,k:number)=>{
                            return(
                                <Col className={"mb-md-4 mb-3"} key={k} md={(k === 0 ) ? 9 : 3}>
                                    {
                                        (k ===0) ?
                                            <GridArticle data={val.articles} catName={val.catName} catId={val.id} />
                                            :
                                            <MergeArticle data={val.articles.filter((v:any,kk:number)=>{return kk <= 3})} catName={val.catName} catId={val.id} />
                                    }

                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>

        </>

    )
}
export default Home
