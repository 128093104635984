import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {data, setting} from "../helper/setting";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import Category from "../container/catBox/category";
import MergeArticle from "../container/catBox/mergeArticle";
import {Helmet} from "react-helmet-async";

function CategoryPage() {
    const {id} = useParams()
    const [data2,setdata] = useState([] as any)
    const [page,setpage] = useState(0)
    const [loading,setloading] = useState(true)
    useEffect(()=>{
        getData()
    },[id])

    const getData = () => {
        setloading(true)
        axios.get(setting.apiUrl+"CategoryApi/Get/"+id+"?page="+page)
            .then((res)=>{
                setdata(res.data.Data)
                setloading(false)
            }).catch((error)=> {
        })
    }


    document.addEventListener("scroll",(e)=>{

        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
            return;
        }

        //setpage(page+1)

        //getData()
    })

    return(
        <>

        <Container className={"py-4"}>
            <Row className={"justify-content-center gx-md-4 "}>
                <Col md={8} className={"pt-3 "}>
                 {
                    loading ?
                        <Col className={"text-center my-5 py-5"} md={12}><Spinner aria-label={"انتظر من فضلك"} variant={"success"} /></Col>
                        :
                       <>
                           {data2 &&
                               <>
                                   <Helmet async title={data.Setting.title+" - "+data2[0].catName}   defaultTitle={data.Setting.title}>
                                       <link rel="canonical" href={window.location.origin + window.location.pathname} />
                                   </Helmet>

                                       <Category data={data2} catName={data2[0].catName} />

                               </>
                           }
                       </>
                }
                </Col>

                <Col md={3} className={"position-relative pt-3 "}>
                    <div className={"position-sticky start-0 top-0"}>
                        <MergeArticle catName={"زوارنا يتصفحون الآن"} data={data.mainNews.filter((v:any,k:any)=>{return k <=4})}/>
                    </div>
                </Col>

            </Row>
        </Container>
        </>
    )
}
export default CategoryPage
