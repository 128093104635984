import {Link} from "react-router-dom";
import Picture from "../picture";
import "./article.css"
import {getarticleUrl} from "../../helper/function";
function ArticleOverlay({data={color:"",id:0,title:"",catName:"",image:""},ratio={w:350,h:350 / 1.777777},mobRatio={w:350,h:350 / 1.777777}}) {
    return(
        <Link to={getarticleUrl(data)} title={data.title}>
            <article>
                {
                    data.catName &&
                    <span style={{backgroundColor:data.color}} className={"catName"}>{data.catName}</span>
                }
                <Picture image={data.image} title={data.title} mobRatio={mobRatio} ratio={ratio} />
                <div className={"titleDiv"}>
                    <h3>{data.title}</h3>
                </div>
            </article>
        </Link>
    )
}
export default ArticleOverlay
