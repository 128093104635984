import {imgSize, setting} from "../helper/setting";
import {Image} from "react-bootstrap";

function Picture({ratio={w:0,h:0},mobRatio={w:0,h:0},image="",title=""}) {
    return(
       <>
           <Image loading={"lazy"} className={"d-none d-md-block"} srcSet={setting.img_path + parseInt(ratio.w.toString()) +"/"+parseInt(ratio.h.toString())+"/" +setting.public + image} alt={setting.title + " - " +title} width={"100%"} />
           <Image loading={"lazy"} className={"d-md-none d-block"} srcSet={setting.img_path + parseInt(mobRatio.w.toString()) +"/"+parseInt(mobRatio.h.toString())+"/" +setting.public + image} alt={setting.title + " - " +title} width={"100%"} />
       </>
    )
}
export default Picture
