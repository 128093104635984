import {Col, Row} from "react-bootstrap";
import ArticleSummary from "../articleBox/articleSummary";
import {Link} from "react-router-dom";
import Picture from "../picture";
import ArticleOverlay from "../articleBox/articleOverlay";
import ArticleSmall from "../articleBox/articlesmall";
import {getUrl} from "../../helper/function";

function MergeArticle({data=[] as any,catName="",catId=0}) {
    const ratio={w:120,h:106 }
    const mobRatio={w:150,h:150 }
    return(
        <>
            <h2 className={"cat_header"}>
                <Link to={getUrl({type:1,title:catName,id:catId})}>{catName}</Link>
            </h2>
            <Row>
                {
                    data.map((val:any,k:number)=>{
                        return(
                            <Col md={12} key={k}>
                                {
                                    k == 0 ?
                                        <div className={"mb-3"}>
                                            <ArticleOverlay data={val}  />
                                        </div>
                                        :
                                        <ArticleSmall data={val} />
                                }

                            </Col>
                        )
                    })
                }
            </Row>
        </>
    )
}
export default MergeArticle
