import React, {useEffect, useState} from "react";
import axios from "axios";
import {data, setting} from "../helper/setting";
import {useParams} from "react-router-dom";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {Helmet} from "react-helmet-async";

function Page() {
    const {id} = useParams()
    const [data2,setdata] = useState([] as any)
    const [loading,setloading] = useState(true)
    useEffect(()=>{
        getData()
    },[id])

    const getData = () => {
        setloading(true)
        axios.get(setting.apiUrl+"PageApi/GetData/"+id)
            .then((res)=>{
                setdata(res.data.Data)
                setloading(false)
            }).catch((error)=> {

            })
    }


    return(
        <>
            <h1 className="page-header-hidden">{setting.title}</h1>
            <Container>
                <Row className={"justify-content-center"}>
                    {
                        loading ?
                            <div className={"text-center my-5 py-5"} ><Spinner aria-label={"انتظر من فضلك"} variant={"success"} /></div>
                            :
                            <Col md={10} className={"py-5"}>
                                <Helmet async title={data.Setting.title + " - "+ data2.title}   defaultTitle={data.Setting.title}>
                                    <link rel="canonical" href={window.location.origin + window.location.pathname} />
                                </Helmet>

                                <h2 className={"cat_header h4 ps-2"}><span>{data2.title}</span></h2>
                                <div dangerouslySetInnerHTML={{__html:data2.details}}></div>
                            </Col>
                    }
                </Row>
            </Container>
        </>

    )
}
export default Page
