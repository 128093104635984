import React, {useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.rtl.min.css"
import './App.css';
import Header from "./container/header/header";
import axios from "axios";
import {data, setting} from "./helper/setting";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {wait} from "@testing-library/user-event/dist/utils";
import Loading from "./container/loading";
import {Route, Routes, useLocation} from "react-router-dom";
import Home from "./layout/main";
import Footer from "./container/footer/footer";
import Details from "./layout/details";
import Page from "./layout/page";
import CategoryPage from "./layout/categoryPage";
import {HelmetProvider} from "react-helmet-async";
import Search from "./layout/search";

function App() {
    const location= useLocation()
    const [loading,setloading] = useState(true)
    useEffect(()=>{
        getData()
    },[])

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[window.location.pathname])

    const getData = async () => {
        await axios.get(setting.apiUrl + "OptionApi/Get", {
            method: "get"
        }).then((res) => {
            data.Category = res.data.Category
            data.Setting = res.data.Setting
            data.mainNews = res.data.mainNews
            data.catNews = res.data.catNews
            data.vodNews = res.data.vodNews
            data.footerCateg = res.data.footerCateg
            setloading(false)
        }).catch((error) => {

        })
    }
  return (
    <div className="App">
        <HelmetProvider>
            {loading ?
                <Loading />
                :
                <>
                    <Header />
                    <Routes>
                        <Route path={"*"} element={<Home />} />
                        <Route path={"n/:id/:title"} element={<Details />}/>
                        <Route path={"cat/:id/:title"} element={<CategoryPage />}/>
                        <Route path={"page/:id/:title"} element={<Page />}/>
                        <Route path={"tag/:title"} element={<Search />}/>

                    </Routes>
                    <Footer />
                </>
            }
        </HelmetProvider>


    </div>
  );
}

export default App;
