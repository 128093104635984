import {Link} from "react-router-dom";
import Picture from "../picture";
import {Col, Row} from "react-bootstrap";
import {data} from "../../helper/setting";
import ArticleSummary from "../articleBox/articleSummary";
import {getUrl} from "../../helper/function";

function GridArticle({data=[] as any,catName="آخر الأخبار",catId=0}) {
    const ratio={w:350,h:350 / 1.777777}
    const mobRatio={w:350,h:350 / 1.777777}
    return(
        <>
            <h2 className={"cat_header"}>
                <Link to={getUrl({type:1,title:catName,id:catId})}>{catName}</Link>
            </h2>
            <Row>
                {
                   data.map((val:any,key:number)=>{
                        return(
                            <Col key={key} className={"mb-3"} md={4}>
                                <ArticleSummary data={val} ratio={ratio} mobRatio={mobRatio} />
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    )
}
export default GridArticle
